/* RichTextEditor.css */
.rich-text-editor-wrapper {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .rich-text-editor {
    padding: 10px;
    min-height: 200px;
    font-family: Arial, sans-serif;
  }
  
  .rich-text-editor-toolbar {
    border-bottom: 1px solid #ccc;
  }