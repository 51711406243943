.calendar {
  width: 100%;
  margin: auto;
}

.day-of-week, .day, .empty-day {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.day-of-week:last-child, .day:last-child, .empty-day:last-child {
  border-right: none;
}

.day:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.staff-column {
  border-right: 1px solid #000;
  min-width: 130px;
}
.staff-column-last {
  min-width: 130px;
}

.staff-name {
  text-align: center;
  padding: 5px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events {
  min-height: 100px;
  position: relative;
}

.time-slot {
  border-bottom: 1px solid #ccc;
}

.event {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  width: 90%;
  left: 5%;
  box-sizing: border-box;
}

/* Media query for mobile devices */
@media screen and (max-width: 767px) {
  .calendar {
    overflow-x: auto;
  }

  .day-of-week,
  .day,
  .empty-day {
    min-width: 40px;
  }

  .staff-column {
    min-width: 80px;
  }

  .staff-name p {
    font-size: 12px;
  }

  .events {
    min-height: 80px;
  }

  .time-slot .time-label {
    left: 2px;
    font-size: 10px;
  }

  .event {
    /* padding: 2px; */
    border-radius: 2px;
    width: 95%;
    left: 2.5%;
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .day-of-week .month {
    display: none;
  }
}
