@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.content {
  height: 100vh;
  overflow-y: auto;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  /* display: none; */
}

.show-scrollbar::-webkit-scrollbar {
  display: unset;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #959595;
}

textarea:focus {
  outline: none;
}

.fc-icon-chevron-left::before,
.fc-icon-chevron-right::before {
  content: '⇦' !important;
  font-size: 15px;
  position: relative;
  top: -3.5px;
}

.fc-icon-chevron-right::before {
  content: '⇨' !important;
}

.fc-icon-x:before {
  content: 'ⓧ' !important;
}

.fc-event-main,
.fc-list-event {
  cursor: pointer;
}
/* * {
  outline: 1px solid;
} */

.SnackbarItem-variantSuccess {
  background-color: #bae2bc !important
}

.SnackbarItem-variantError {
  background-color: #f7645f !important
}