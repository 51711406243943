*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

:root {
  --accent-color: #e27e2d;
}

body {
  margin: 0;
}

.full-screen-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('/public/assets/bgimage.png');
  height: calc(100vh - 50px);
  width: 100vw;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  /* width: 80%; */
  /* height: 80%; */
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  /* background: url('/public/wave-orange.svg') no-repeat rgba(255, 255, 255, 0.7); */
  background-position: bottom;
  background-size: 200%;
}

.signup-container {
  flex: 2;

  width: 100%;

  background-position: bottom;

  background-size: 200%;
  display: flex;
  align-items: center;
}

.login-title {
  background-color: transparent;

  color: #242331;

  font-family: Fredoka;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 55px */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sign-title {
  background-color: transparent;

  color: white;

  font-family: Fredoka;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 55px */
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.logo {
  height: 70px;
  display: block;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.login-button:hover,
.login-button:focus,
.login-button:active {
  background-color: hsl(var(--primary-hsl), 0.4);
  color: ghostwhite;
}

.cphi-card {
  position: absolute;
  right: 30px;
  bottom: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cphi-card img {
  height: 40px;
  width: auto;
}
.cphi-card p {
  margin-bottom: 2px;
  color: #797979;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login-button {
  position: relative;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
  border: none;
  background: linear-gradient(to right, #239bd7, #0192D5);
  color: ghostwhite;
  overflow: hidden;
  width: 100%;
  margin: auto;
  text-transform: none;
}

.login-button span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
  padding: 0.5em;
}

.login-button::before,
.login-button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  color: ghostwhite;
}

.login-button::before {
  content: '';
  background-color: #0192D5;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.login-button:hover::before {
  transform: translate3d(100%, 0, 0);
}

.login-button:active {
  transform: scale(0.95);
}

.input-group {
  position: relative;
}

.input {
  border: none;
  border-radius: 8px;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: black;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 20px;
  width: 35vw;
  background-color: #f4f4f4;
}

.user-label {
  position: absolute;
  left: 15px;
  color: black;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.input:focus,
input:valid,
input:active {
  outline: none;
}

.input:focus ~ .user-label,
input:valid ~ .user-label,
input:-webkit-autofill ~ .user-label {
  transform: translateY(-50%) scale(1);
  background-color: #0192D5;
  padding: 0 0.5em;
  color: white;
  border-radius: 25em;
  border: 2px solid #0192D5;
}

.input.error {
  border: 1.5px solid red;
}

.login-form {
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}

.privacy {
  color: #797979;
  position: absolute;
  left: 30px;
  bottom: 0px;
  font-family: Nunito;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* .contactus {
  text-align: center;
  text-decoration: none;
  color: ghostwhite;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
}

.contactus button:hover {
  /* background-color: #00385d; */
/* background: linear-gradient(to right, #e27e2d, #e02900);
} */

/* .contactus button {
  border-radius: 25em;
  border: none;
  background-color: #0099ff;
  overflow: hidden;
  color: ghostwhite;
  font-size: 20px;
  padding: 10px 20px;
  position: bottom;
} */
.error-msg {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0;
  color: rgb(213, 0, 0);
}
.welcome {
  color: #242331;
  text-align: center;
  font-family: Fredoka;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 55px */
}

.input-group .MuiIconButton-root {
  position: absolute;
  right: 20px;
  top: 37%;
  transform: translateY(-50%);
  color: #9e9e9e;
}

.signup-link {
  transition: color 0.3s ease; /* Add a smooth color transition */
}

.signup-link:hover {
  color: #004272;
}

.login-link {
  transition: color 0.3s ease; /* Add a smooth color transition */
}

.login-link:hover {
  color: #e7222c;
}

.signup-section {
  color: white;
}

@media only screen and (max-width: 900px) {
  .login-container {
    margin-bottom: 100px;
  }
  .input {
    width: 250px;
  }

  .login-button {
    padding: 5px 10px;
  }
}
.chat {
  position: absolute;
  right: 10px;
  bottom: 80px;
  max-height: 10%;
}
@media only screen and (max-width: 600px) {
  .login-container {
    border-radius: 30px;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
  }

  .logo {
    width: 150px;
    height: 150px;
  }

  .back {
    font-size: 20px;
    font-weight: bold;
  }
  .login-title {
    margin: 10px 0;
    padding-bottom: 0.5rem;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  .input {
    border-radius: 10px;
    font-size: 0.8rem;
    margin-bottom: 15px;
    height: 40px;
    width: 75vw;
  }

  .user-label {
    left: 10px;
  }
  .input:focus ~ .user-label,
  input:valid ~ .user-label,
  input:-webkit-autofill ~ .user-label {
    font-size: 10px;
    border-radius: 25em;
  }

  .error-msg {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0;
    font-size: 12px;
    color: rgb(213, 0, 0);
  }

  .login-button {
    width: 75vw;
    margin: auto;
    /* border-radius: 100px; */
  }

  .login-button span {
    padding: 0.4em;
    font-size: 10px;
  }
  .signup-section {
    margin-bottom: 30px;
  }
  .chat {
    bottom: 70px;
    height: 70px;
  }

  .privacy {
    left: 20px;
    bottom: 2px;
    font-size: 12px;
  }

  .cphi-card {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 20px;
    bottom: 8px;
  }

  .cphi-card img {
    height: 25px;
  }
  .cphi-card p {
    font-size: 10px;
    bottom: 17px;
    right: 10px;
    position: absolute;
  }
}

@media only screen and (max-width: 1800px) {
  .logo {
    height: 50px;
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    margin-bottom: 40px;
    margin-top: 0px;
    /* width: 80%; */
    /* height: 80%; */
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    /* background: url('/public/wave-orange.svg') no-repeat rgba(255, 255, 255, 0.7); */
    background-position: bottom;

    background-size: 200%;
  }
  .logo {
    height: 40px;
    width: auto;
    margin-bottom: 10px;
  }
  .signup-container {
    width: 100%;
    background-color: white;
    position: absolute;
    top: 340px;
    z-index: 1;
    display: flex;
    height: 30%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    align-items: center;
  }
  .sign-title {
    text-align: center;
  }
}
