:root {
    --accent-color: #0397D5;
    --carego-primary: rgba(35, 155, 215, 1);
}

.custom-form {
    max-width: 880px;
    margin: 0 auto;
}

.custom-form .form-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 60px;
}

.input-group {
    position: relative;
}

.input-field {
    border: solid 1.5px #9e9e9e;
    background: none;
    padding: 1em;
    font-size: 12px;
    color: black;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1);
    width: 100%;
    background-color: whitesmoke;
}

.input-field:focus, 
.input-field:not(:placeholder-shown) {
    outline: none;
}

.select .input-field:not(:placeholder-shown) {
    border: solid 1.5px #9e9e9e;
}

.select-label,
.input-field:focus ~ .input-label, 
.input-field:not(:placeholder-shown) ~ .input-label {
    transform: translateY(-50%) scale(1);
    background-color: var(--accent-color);
    padding: 0 0.5em;
    color: black;
    border-radius: 20px;
    
}

.select .input-field:focus ~ .input-label {
    opacity: 1;
    transition: opacity 0.2s ease-out
}

.select .input-label {
   opacity: 0;
}

.select-label,
.input-label {
    font-size: 12px;
    position: absolute;
    left: 15px;
    color: black;
    pointer-events: none;
    transform: translateY(1em);
    transition: 150ms cubic-bezier(0.4,0,0.2,1);
}

.select-label {
    transform: translateY(-11px);
}

.error-message {
    color: rgb(249, 88, 88);
    display: block;
    min-height: 20px;
    padding-inline: 15px;
}



.pro-inner-list-item > div > ul {
    padding: 0 !important;
}

.pro-inner-list-item.popper-element {
    padding-left: 15px !important;

}

.light .popper-inner {
    background-color: #f2f0f0 !important;
    box-shadow: 0px 0px 10px 0px #c2c1c1;
}

.dark .popper-inner {
    background-color: #232426 !important;
    box-shadow: 0px 0px 10px 0px #0c0c0d;
}
